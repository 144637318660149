import React, { useState, useEffect } from 'react'
import { Form, message, Input, Checkbox } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Link } from 'gatsby'
import { auth0Roles, strapiURL } from '../../../config'
import cookie from 'react-cookies'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { setProducts, setProfileData } from '../../../redux/actions/user'
import useGMT from '../../../hooks/useGTM'

const LoginForm = ({}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const { pushLoginEvent } = useGMT()

  useEffect(() => {
    handlePreLoad()
  }, [])

  const handlePreLoad = () => {
    const rememberUser = localStorage.getItem('remember-user')
    if (rememberUser) {
      form.setFieldsValue({
        username: rememberUser,
      })
    }
  }

  const login = values => {
    setLoading(true)
    let data = {
      username: (values?.username || '').toLowerCase(),
      password: values?.password,
    }
    let maxAge = values?.remember ? 518400 : 259200
    axios
      .post(strapiURL + '/auth0/login', data)
      .then(res => {
        let responseRole = res.data.role.id
        let UserData = res?.data
        if (res?.data?.user?.named_user?.employer?.user) {
          UserData.username =
            res?.data?.user?.named_user?.employer?.user?.username
          UserData.profileData.name =
            res?.data?.user?.named_user?.employer?.name
          UserData.profileData.image =
            res?.data?.user?.named_user?.employer?.image
          UserData.employerId = res?.data?.user?.named_user?.employer?.user?.id
          delete UserData.user.named_user
        }
        let profileData = {
          name: UserData.profileData.name,
          firstName: UserData.profileData.firstName,
          lastName: UserData.profileData.lastName,
          image: UserData.profileData.image
            ? UserData.profileData.image.url
            : responseRole === auth0Roles.Member.id
            ? strapiURL + '/images/default-candidate.png'
            : strapiURL + '/images/default-employer.png',
        }
        if (values?.remember) {
          localStorage.setItem(
            'remember-user',
            (values?.username || '').toLowerCase()
          )
        } else {
          localStorage.removeItem('remember-user')
        }
        dispatch(setProfileData(profileData))
        delete UserData.profileData

        cookie.save('userRole', responseRole, { path: '/', maxAge: maxAge })
        cookie.save('user', UserData, { path: '/', maxAge: maxAge })

        pushLoginEvent()

        axios
          .get(
            strapiURL +
              `/employers/${
                responseRole === auth0Roles?.NamedUser?.id
                  ? UserData.employerId
                  : UserData?.user?.id
              }/subscriptionsAndCredits`
          )
          .then(response => {
            dispatch(setProducts(response?.data))
            if (typeof window !== 'undefined') {
              if (
                responseRole === auth0Roles?.Employer?.id ||
                responseRole === auth0Roles?.NamedUser?.id
              ) {
                window.location.href = '/employer-dashboard/'
              } else {
                window.location.href = '/member-dashboard'
              }
            }

            // closeHandler()
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log('Error Response', err.response.data)
        setLoading(false)
        message.error(err?.response?.data?.message[0]?.error)
      })
  }
  return (
    <div className="bg-white w-full flex flex-wrap rounded max-w-[1024px] mt-[100px] shadow-xl">
      <div className="w-full md:w-2/5 py-5 lg:py-8">
        <div className="px-[30px] lg:px-12 mb-5">
          <img
            className="h-4.5 lg:h-10"
            src="/images/logo.png"
            alt="Medreps logo"
          />
        </div>
        <div className="px-[30px] lg:px-12 pt-9 lg:pt-16">
          <p className="font-playfair text-headingThree lg:text-headingTwo font-normal leading-tight mb-0.5">
            Login
          </p>
          <p className="text-sm font-sans">
            Current Members Login to Your Account.
          </p>
          <Form
            className="pt-6"
            form={form}
            name="candidate-form"
            layout="vertical"
            onFinish={login}
          >
            <Form.Item
              name="username"
              className="theme-form-item"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="E-Mail"
                className="placeholder-charcoal focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
            <Form.Item
              className="theme-form-item-password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter password',
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              className="theme-form-item"
              name="remember"
              valuePropName="checked"
            >
              <Checkbox checked={true} className="font-semibold font-sans">
                Remember me next time
              </Checkbox>
            </Form.Item>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-voyage py-2 px-6 text-white font-sans rounded font-semibold text-sn"
              >
                {loading ? 'Logging in...' : 'Log In'}
              </button>
              <Link
                to="/forget-password"
                className="text-voyage text-xs underline"
              >
                Forgot Password?
              </Link>
            </div>
          </Form>

          <div className="pt-6">
            <p className="font-sans font-normal text-last mb-0">
              Not a member yet? &nbsp;
              <Link
                to="/signup"
                className="text-merlot font-semibold cursor-pointer text-last underline"
              >
                Create an Account
              </Link>
            </p>
            <p className="text-last mt-8">
              By signing in to MedReps.com, you agree to MedReps.com's{' '}
              <a
                target="_blank"
                href="/privacy-policy"
                className="text-lightBlue"
              >
                Terms of Use & Privacy Policy.
              </a>
            </p>
          </div>
          <p className="mt-8 font-sans text-last mb-0">
            2000-2022 MedReps. All rights reserved.
          </p>
        </div>
      </div>
      <div className="w-full md:w-3/5 bg-voyage rounded-tr rounded-br relative hidden md:block">
        <div className="flex flex-col justify-center items-center pt-12">
          <img
            src="/images/login-modal-image.png"
            alt="Login phone icon"
            className="w-2/5"
          />
          <p className="font-sans font-semibold text-white text-xl lg:text-2xl mt-16 text-center">
            The go-to site for medical sales jobs and advice
          </p>
        </div>
      </div>
    </div>
  )
}
export default LoginForm
