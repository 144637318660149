import React from 'react'
import Wrapper from '../components/wrapper'
import LoginForm from '../components/pageComponents/loginForm'
export default function LoginPage({ location }) {
  return (
    <Wrapper location={location} title="Login | MedReps.com">
      <div className="flex justify-center">
        <LoginForm />
      </div>
    </Wrapper>
  )
}
